import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import reducer from '../store/reducers'
import thunk from 'redux-thunk';

// import {createLogger} from 'redux-logger'
// const logger = createLogger();
const allStoreEnhancers = compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
)

export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducer
    }),
    allStoreEnhancers,
  );
}