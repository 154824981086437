import * as Types from '../../constants/actionTypes';
const defaultState = {
    portalUsersList: [],
    selectedUserData: {},
}
export default (state = defaultState, action) => {
    switch (action.type) {
        /* Portal users reducers */
        case Types.LIST_PORTAL_USER:
            return {
                ...state,
                portalUsersList: action.portalUsersList
            }
        case Types.PORTAL_USER_ERROR:
            return {
                ...state,
                userError: action.userError
            }
        case Types.PORTAL_USER_ADDED:
            return {
                ...state,
                addedUser:action.addedUser
            }
        case Types.PORTAL_USER_UPDATED:
                return {
                    ...state,
                    user: action.user
            }    
        case Types.PORTAL_ONE_USER_INFO:
            return {
                ...state,
                selectedUserData: action.selectedUserData
            }
        case Types.DELETE_PORTAL_USER:
            return {
                ...state,
                deletedUser: action.deletedUser
            }    
        default:
            return state
    }

}

