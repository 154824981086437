export const CLEAR = 'CLEAR'
export const LOGIN = 'LOGIN'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR'

export const IS_USER_AUTHENTICATED = 'IS_USER_AUTHENTICATED'
export const PROGRAM_ACTIVE_STATE = 'PROGRAM_ACTIVE_STATE'
export const PROGRAM_CONFIG_ACTIVE_STATE = 'PROGRAM_CONFIG_ACTIVE_STATE'
export const ON_LOGOUT= 'ON_LOGOUT'

export const USER_ROLES = 'USER_ROLES'
export const USER_RIGHT_OBJECTS = 'USER_RIGHT_OBJECTS'
export const USER_RIGHTS = 'USER_RIGHTS'
export const ASSETS_TYPE_COUNT_SUCCESS = "ASSETS_TYPE_COUNT_SUCCESS"
export const ASSETS_TYPE_COUNT_ERROR = "ASSETS_TYPE_COUNT_ERROR"

export const SET_ROLES_DATA = 'SET_ROLES_DATA'
export const SET_ROLE_IDS = 'SET_ROLE_IDS'
export const SET_RIGHT_OBJECTS = 'SET_RIGHT_OBJECTS'
export const SET_ROLE_RIGHTS = 'SET_ROLE_RIGHTS'
export const ROLES_ERROR = 'ROLES_ERROR'

export const SHOW_DATA = 'SHOW_DATA'
export const UPDATE_USER = 'UPDATE_USER'

/* Users Information */
export const LIST_USER_DATA = 'LIST_USER_DATA'
export const USERS_ERROR = 'USERS_ERROR'
export const NEW_ADDED_USER = 'NEW_ADDED_USER'
export const USER_ROLES_DATA = 'USER_ROLES_DATA'
export const USER_ROLE_ERROR = 'USER_ROLE_ERROR'
export const SELECTED_USER_DATA = 'SELECTED_USER_DATA'
export const ADDED_USER_ROLE = 'ADDED_USER_ROLE'
export const DELETED_USER_ROLE = 'DELETED_USER_ROLE'

export const USER_CLIENTS_ERROR = 'USER_CLIENTS_ERROR'
export const USER_CLIENTS_DATA = 'USER_CLIENTS_DATA'
export const USER_PORTALS_DATA = 'USER_PORTALS_DATA'
export const DELETED_USER_PORTAL = 'DELETED_USER_PORTAL'

export const ADDED_USER_PORTAL = 'ADDED_USER_PORTAL'
export const ADDED_USER_CLIENT_INFO = 'ADDED_USER_CLIENT_INFO'
export const DELETED_USER_CLIENT_INFO = 'DELETED_USER_CLIENT_INFO'
export const SAVE_CURRENT_PAGE = 'SAVE_CURRENT_PAGE'
export const DELETE_FORMATTED_CLIENTS = 'DELETE_FORMATTED_CLIENTS'
export const USER_FORMATTED_CLIENTS = 'USER_FORMATTED_CLIENTS'
export const DELETED_USER_ADMIN = 'DELETED_USER_ADMIN'

/* All Roles */
export const ALL_ROLES_DATA = 'ALL_ROLES_DATA'
export const USER_ROLE_LIST = 'USER_ROLE_LIST'
export const ADDED_ROLE = 'ADDED_ROLE'
export const UPDATED_ROLE = 'UPDATED_ROLE'
export const DELETED_ROLE_DATA = 'DELETED_ROLE_DATA'
export const ADDED_NEW_FORMATTED_CLIENT = 'ADDED_NEW_FORMATTED_CLIENT'
export const RIGHTS_BY_ROLE_DATA = 'RIGHTS_BY_ROLE_DATA'

/* All RightObjects */
export const ALL_RIGHT_OBJECTS_DATA = 'ALL_RIGHT_OBJECTS_DATA'
export const RIGHT_OBJECTS_ERROR = 'RIGHT_OBJECTS_ERROR'

/* Password Handling */
export const ADMIN_FORGOT_PASSWORD_ERROR = 'ADMIN_FORGOT_PASSWORD_ERROR'
export const ADMIN_CHANGE_PASSWORD_ERROR = 'ADMIN_CHANGE_PASSWORD_ERROR'


/* ------------- Portal User Constants ------------ */
export const LIST_PORTAL_USER='LIST_PORTAL_USER'
export const PORTAL_USER_ADDED = 'PORTAL_USER_ADDED'
export const PORTAL_USER_ERROR = 'PORTAL_USER_ERROR'
export const PORTAL_USER_UPDATED ='PORTAL_USER_UPDATED'
export const PORTAL_ONE_USER_INFO = 'PORTAL_ONE_USER_INFO'
export const DELETE_PORTAL_USER = 'DELETE_PORTAL_USER'

/** User Logs */
export const PORTAL_LOG_ERROR = "PORTAL_LOG_ERROR"
export const USER_LOGS_INFO = "USER_LOGS_INFO"
export const PORTAL_USER_LOGS_INFO = "PORTAL_USER_LOGS_INFO"

// Technoogies

export const TECHNOLOGIES_LIST = 'TECHNOLOGIES_LIST'
export const TECHNOLOGIES_LIST_ERROR = 'TECHNOLOGIES_LIST_ERROR'
export const ADD_TECHNOLOGY = 'ADD_TECHNOLOGY'
export const ADD_TECHNOLOGY_ERROR = 'ADD_TECHNOLOGY_ERROR'
export const EDIT_TECHNOLOGY = 'EDIT_TECHNOLOGY'
export const EDIT_TECHNOLOGY_ERROR = 'EDIT_TECHNOLOGY_ERROR'
export const DELETE_TECHNOLOGY = 'DELETE_TECHNOLOGY'
export const DELETE_TECHNOLOGY_ERROR = 'DELETE_TECHNOLOGY_ERROR'

export const TECHNOLOGY_PRODUCT_LIST= 'TECHNOLOGY_PRODUCT_LIST'
export const TECHNOLOGY_PRODUCT_LIST_ERROR= 'TECHNOLOGY_PRODUCT_LIST_ERROR'
export const ADD_TECHNOLOGY_PRODUCT= 'ADD_TECHNOLOGY_PRODUCT'
export const ADD_TECHNOLOGY_PRODUCT_ERROR= 'ADD_TECHNOLOGY_PRODUCT_ERROR'
export const EDIT_TECHNOLOGY_PRODUCT= 'EDIT_TECHNOLOGY_PRODUCT'
export const EDIT_TECHNOLOGY_PRODUCT_ERROR= 'EDIT_TECHNOLOGY_PRODUCT_ERROR'
export const DELETE_TECHNOLOGY_PRODUCT= 'DELETE_TECHNOLOGY_PRODUCT'
export const DELETE_TECHNOLOGY_PRODUCT_ERROR= 'DELETE_TECHNOLOGY_PRODUCT_ERROR'

export const BRANDS_LIST = 'BRANDS_LIST'
export const BRANDS_LIST_ERROR = 'BRANDS_LIST_ERROR'
export const ADD_BRAND = 'ADD_BRAND'
export const ADD_BRAND_ERROR = 'ADD_BRAND_ERROR'
export const EDIT_BRAND = 'EDIT_BRAND'
export const EDIT_BRAND_ERROR = 'EDIT_BRAND_ERROR'
export const DELETE_BRAND = 'DELETE_BRAND'
export const DELETE_BRAND_ERROR = 'DELETE_BRAND_ERROR'

export const BRAND_PRODUCT_LIST= 'BRAND_PRODUCT_LIST'
export const BRAND_PRODUCT_LIST_ERROR= 'BRAND_PRODUCT_LIST_ERROR'
export const ADD_BRAND_PRODUCT= 'ADD_BRAND_PRODUCT'
export const ADD_BRAND_PRODUCT_ERROR= 'ADD_BRAND_PRODUCT_ERROR'
export const EDIT_BRAND_PRODUCT= 'EDIT_BRAND_PRODUCT'
export const EDIT_BRAND_PRODUCT_ERROR= 'EDIT_BRAND_PRODUCT_ERROR'
export const DELETE_BRAND_PRODUCT= 'DELETE_BRAND_PRODUCT'
export const DELETE_BRAND_PRODUCT_ERROR= 'DELETE_BRAND_PRODUCT_ERROR'

export const GET_PAGE_DATA = 'GET_PAGE_DATA'
export const GET_PAGE_DATA_ERROR = 'GET_PAGE_DATA_ERROR'
export const ADD_PAGE_DATA = 'ADD_PAGE_DATA'
export const ADD_PAGE_DATA_ERROR = 'ADD_PAGE_DATA_ERROR'

export const DELETE_CLIENT = 'DELETE_CLIENT'
export const DELETE_CLIENT_ERROR = 'DELETE_CLIENT_ERROR'

export const ADD_CLIENT = 'ADD_CLIENT'
export const ADD_CLIENT_ERROR = 'ADD_CLIENT_ERROR'

export const DELETE_MEMBER = 'DELETE_MEMBER'
export const DELETE_MEMBER_ERROR = 'DELETE_MEMBER_ERROR'

export const GET_BID_DATA = 'GET_BID_DATA'
export const GET_BID_DATA_ERROR = 'GET_BID_DATA_ERROR'
export const GET_INQUIRY_DATA = 'GET_INQUIRY_DATA'
export const GET_INQUIRY_DATA_ERROR = 'GET_INQUIRY_DATA_ERROR'
export const GET_ORDER_DATA = 'GET_ORDER_DATA'
export const GET_ORDER_DATA_ERROR = 'GET_ORDER_DATA_ERROR'
export const GET_LICENSE_DATA = 'GET_LICENSE_DATA'
export const GET_LICENSE_DATA_ERROR = 'GET_LICENSE_DATA_ERROR'