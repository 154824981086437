import * as Types from '../../constants/actionTypes';
const defaultState = {
    bidData:undefined
}
export default (state = defaultState, action) => {
    switch (action.type) {
        /* Portal users reducers */
        case Types.GET_BID_DATA:
            return {
                ...state,
                bidData: action.bidData
            }
        case Types.GET_LICENSE_DATA:
            return {
                ...state,
                licenseData: action.licenseData
            }    
        default:
            return state
    }

}

