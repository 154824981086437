import ThemeOptions from './ThemeOptions';
import auth from './auth'
import user from './user'
import portalUsers from './portalusers'
import technologies from './technologies'
import brands from './brands'
import othersPages from './othersPages';
import bids from './bids'
import orders from './orders'
import inquires from './inquires'
export default {
    auth,
    user,
    ThemeOptions,
    portalUsers,
    technologies,
    brands,
    othersPages,
    bids,
    inquires,
    orders
};
