import {  Route, Redirect } from 'react-router-dom';
import React, {  Suspense, lazy, Fragment } from 'react';
import Auth from '../../models/Auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


import {
    ToastContainer,
} from 'react-toastify';

const Dashboards = lazy(() => import('../../Pages/Dashboards'));
const Masterdata = lazy(() => import('../../Pages/Masterdata'));

const Home = lazy(() => import('../../Pages/Home'));
const OtherPages = lazy(() => import('../../Pages/Others'));
// const Terms = lazy(() => import('../../Pages/Others/Terms'));
// const Policy = lazy(() => import('../../Pages/Others/Policy'))
const AppMain = () => {

    return (
        <Fragment>

            {/* Home Page Data */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                    <div className="spinner-width" >
                        <FontAwesomeIcon  icon={faSpinner} spin />
                    </div>
                        {/* <h6 className="mt-3">
                            Please wait while we load all the HomePage
                        </h6> */}
                    </div>
                </div>
            }>
                <Route path="/home" component={Home} />
            </Suspense>

            {/* Master Data */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                    <div className="spinner-width" >
                        <FontAwesomeIcon  icon={faSpinner} spin />
                    </div>
                       
                    </div>
                </div>
            }>
                <Route path="/masterdata" component={Masterdata}/>
            </Suspense>

            {/* Dashboards */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                    <div className="spinner-width" >
                        <FontAwesomeIcon  icon={faSpinner} spin />
                      </div>
                    </div>
                </div>
            }>
                <Route path="/dashboard" component={Dashboards}/>
            </Suspense>

                {/* Terms */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                       <div className="spinner-width" >
                        <FontAwesomeIcon  icon={faSpinner} spin />
                      </div>
                    </div>
                </div>
            }>
                <Route path="/OtherPages" component={OtherPages}/>
            </Suspense>

                    {/* Privacy Polacy */}

            {/* <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                       <div className="spinner-width" >
                        <FontAwesomeIcon  icon={faSpinner} spin />
                      </div>
                    </div>
                </div>
            }>
                <Route path="/policy" component={Policy}/>
            </Suspense> */}
            
            <Route exact path="/" render={() =>{
                return (!Auth.data)?(
                    <Redirect to="/home/login" />
                ):(
                    <Redirect to="/dashboard/basic" />
                )
            }} />
            
            
            


            <ToastContainer autoClose={2000}/>
        </Fragment>
    )
};

export default AppMain;