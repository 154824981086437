import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';


import {  BrowserRouter} from 'react-router-dom';
import './assets/base.css';
import Main from './Pages/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';

const store = configureStore();
// const rootElement = document.getElementById('root');

// const renderApp = Component => {
//   ReactDOM.render(
//     <Provider store={store}>
//       <BrowserRouter>
//         <Component />
//       </BrowserRouter>
//     </Provider>,
//     rootElement
//   );
// };

// renderApp(Main);



const app = (
  <Provider store={store}>
      <BrowserRouter>
          <Main />
      </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));



// if (module.hot) {
//   module.hot.accept('./Pages/Main', () => {
//     const NextApp = require('./Pages/Main').default;
//     renderApp(NextApp);
//   });
// }

serviceWorker.unregister();

// registerServiceWorker();

