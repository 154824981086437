
let _API_URL
let _IMG_URL 

export const ENV_API_URL = process.env.REACT_APP_BASEURL
export const ENV_IMG_URL = process.env.REACT_APP_IMGURL

export const API_REQUESTS = {
    LOGIN : 'admin/login',
    USERS : 'admin/users',
    PORTALUSERS: 'users',
    TECHNOLOGIS: 'technologis',
    BRANDS:'brands',
    INIT_FORGOT_PSWD : 'admin/user/forgot/password',
    CONFIRM_FORGOT_URL: 'admin/user/reset/password',
    UPDATE_PWD_URL: 'admin/user/update/password',
    
}

const reactEnv = process.env.REACT_APP_ENVIROMENT;

// console.log("API", ENV_API_URL)
// console.log("Image",ENV_IMG_URL)

if(reactEnv === "LOCAL") {
    _API_URL = `${ENV_API_URL}`;
    _IMG_URL = `${ENV_IMG_URL}`;
} else if (reactEnv === "STAGE"){
    _API_URL = `${ENV_API_URL}`;
    _IMG_URL = `${ENV_IMG_URL}`;
}else if (reactEnv === "LIVE") {
    _API_URL = `${ENV_API_URL}`;
    _IMG_URL = `${ENV_IMG_URL}`;
}else if (reactEnv === "DEVELOP") {
    _API_URL = `${ENV_API_URL}`;
    _IMG_URL = `${ENV_IMG_URL}`;
}


export const API_URL = _API_URL
export const IMG_URL = _IMG_URL

export const VALIDATE_REGEX = {
    // email: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
    email: /[a-zA-Z0-9._]*@[a-zA-Z0-9.-]*\.[a-z]*/,
    phone: /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$/im
}