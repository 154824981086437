import * as Types from '../../constants/actionTypes';
const defaultState = {
    brandsList: [],
    updatedBrand:undefined,
    addedBrand:undefined
}
export default (state = defaultState, action) => {
    switch (action.type) {
        /* Portal users reducers */
        case Types.BRANDS_LIST:
            return {
                ...state,
                brandsList: action.brandsList
            }
        case Types.ADD_BRAND:
            return {
                ...state,
                addedBrand: action.addedBrand
            }
        case Types.EDIT_BRAND:
            return {
                ...state,
                updatedBrand: action.updatedBrand
            }
        case Types.DELETE_BRAND:
            return {
                ...state,
                deletedBrand: action.deletedBrand
            }
        case Types.ADD_BRAND_PRODUCT:
            return {
                ...state,
                addedBrandProduct: action.addedBrandProduct
            }
        case Types.BRAND_PRODUCT_LIST:
            return {
                ...state,
                brandProductList: action.brandProductList
            }
        case Types.EDIT_BRAND_PRODUCT:
            return {
                ...state,
                updatedData: action.updatedData
            }
        case Types.DELETE_BRAND_PRODUCT:
            return {
                ...state,
                deletedData: action.deletedData
            }                 
        case Types.CLEAR:
            return {
                ...state,
                addedBrand:undefined,
                updatedBrand:undefined,
                deletedBrand:undefined,
                updatedBrand:undefined
            } 
        default:
            return state
    }

}

