import * as Types from '../../constants/actionTypes';
const defaultState = {
    addedData:undefined
}
export default (state = defaultState, action) => {
    switch (action.type) {
        /* Portal users reducers */
        case Types.GET_PAGE_DATA:
            return {
                ...state,
                pageData: action.pageData
            }
        case Types.ADD_PAGE_DATA:
            return {
                ...state,
                addedData: action.addedData
            }
        case Types.ADD_CLIENT:
            return {
                ...state,
                addedClient: action.addedClient
            }    
        case Types.DELETE_CLIENT:
            return {
                ...state,
                deleteClient: action.deleteClient
            }
        case Types.DELETE_MEMBER:
            return {
                ...state,
                deleteMember: action.deleteMember
            }
        case Types.CLEAR:
            return {
                ...state,
                addedData:undefined,
            } 
        default:
            return state
    }

}

