import * as Types from '../../constants/actionTypes';
const defaultState = {
    usersList: [],
    selectedUserData: {},
    formattedUserClients: []
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.SET_ROLES_DATA:
            return {
                ...state,
                rolesData: action.rolesData
            }
        case Types.SET_ROLE_IDS:
            return {
                ...state,
                roleIds: action.roleIds
            }
        case Types.SET_RIGHT_OBJECTS:
            return {
                ...state,
                rightObjects: action.rightObjects
            }
        case Types.SET_ROLE_RIGHTS:
            return {
                ...state,
                rights: action.rights
            }
        case Types.UPDATE_USER:
            return {
                ...state,
                selectedUserData:action.selectedUserData,
                updatedUserData: action.updatedUserData
            }
        case Types.SHOW_DATA:
            return {
                ...state,
                color: action.color
            }
        /* Admin users reducers */
        case Types.LIST_USER_DATA:
            return {
                ...state,
                usersList: action.usersList
            }
        case Types.USERS_ERROR:
            return {
                ...state,
                userError: action.userError
            }
        case Types.NEW_ADDED_USER:
            return {
                ...state,
                addedUser:action.addedUser
            }
        case Types.SELECTED_USER_DATA:
            return {
                ...state,
                selectedUserData: action.selectedUserData 
            }
        case Types.DELETED_USER_ADMIN:
                return {
                    ...state,
                    deletedUser: action.deletedUser
                } 
        default:
            return state
    }

}

