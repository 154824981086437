import * as Types from '../../constants/actionTypes';
const defaultState = {
    inquiresData:undefined
}
export default (state = defaultState, action) => {
    switch (action.type) {
        /* Portal users reducers */
        case Types.GET_INQUIRY_DATA:
            return {
                ...state,
                inquiresData: action.inquiresData
            }
        default:
            return state
    }

}


