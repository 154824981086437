import * as Types from '../../constants/actionTypes';
const defaultState = {
    technologiesList: [],
    updatedTechnology:undefined,
    addedTechnology:undefined,
    techProductList:[]
}
export default (state = defaultState, action) => {
    switch (action.type) {
        /* Portal users reducers */
        case Types.TECHNOLOGIES_LIST:
            return {
                ...state,
                technologiesList: action.technologiesList
            }
        case Types.ADD_TECHNOLOGY:
            return {
                ...state,
                addedTechnology: action.addedtechnology
            }
        case Types.EDIT_TECHNOLOGY:
            return {
                ...state,
                updatedTechnology: action.updatedTechnology
            }
        case Types.DELETE_TECHNOLOGY:
            return {
                ...state,
                deletedTechnology: action.deletedTechnology
            }    
        case Types.ADD_TECHNOLOGY_PRODUCT:
            return {
                ...state,
                addedTechProduct: action.addedTechProduct
            } 
        case Types.TECHNOLOGY_PRODUCT_LIST:
            return {
                ...state,
                techProductList: action.techProductList
            }  
        case Types.EDIT_TECHNOLOGY_PRODUCT:
            return {
                ...state,
                updatedData: action.updatedData
            }
        case Types.DELETE_TECHNOLOGY_PRODUCT:
            return {
                ...state,
                deletedData: action.deletedData
            }                
        case Types.CLEAR:
            return {
                ...state,
                addedTechnology:undefined,
                updatedTechnology:undefined,
                deletedTechnology:undefined
            } 
        default:
            return state
    }

}

